import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom'; // Import useNavigate and Link from react-router-dom
import { auth } from '../firebase'; // Import Firebase Auth
import { signInWithEmailAndPassword } from 'firebase/auth'; // Import signInWithEmailAndPassword from firebase/auth
import '../components/AdminLoginPage.css'; // Import CSS

const AdminLoginPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Log email and password
      console.log("Attempting login with email:", email, "and password:", password);

      // Sign in using Firebase Authentication
      await signInWithEmailAndPassword(auth, email, password); // Updated for v9+

      // If successful, navigate to admin dashboard
      console.log("Login successful!");
      navigate('/admin');
    } catch (error) {
      console.error('Error during login:', error);

      // Firebase authentication error handling
      if (error.code === 'auth/user-not-found') {
        setError('No user found with this email.');
      } else if (error.code === 'auth/wrong-password') {
        setError('Incorrect password.');
      } else {
        setError('An error occurred while logging in.');
      }
    }
  };

  return (
    <div className="admin-login-container">
      <h1>Admin Login</h1>
      <div className="login-box">
        <form onSubmit={handleSubmit}>
          <div>
            <label>Email:</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div>
            <label>Password:</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <button type="submit">Login</button>
          {error && <p className="error-message">{error}</p>}
        </form>
        
        {/* Registration link for users who don't have an account */}
        <div className="registration-link">
          <p>Don't have an account? <Link to="/admin/register">Register here</Link></p>
        </div>
      </div>
    </div>
  );
};

export default AdminLoginPage;
