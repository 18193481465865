import React, { useEffect, useState } from 'react';

import { db } from '../firebase'; // '..' means go up one level to 'src'
import { collection, getDocs } from 'firebase/firestore';
import './CustomerSupport.css'; // We'll create this CSS file for styling

const CustomerSupport = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'users')); // Fetch users from Firestore
        const usersData = querySnapshot.docs.map(doc => doc.data()); // Map Firestore data
        setUsers(usersData); // Store users in state
      } catch (error) {
        console.error('Error fetching users:', error);
      } finally {
        setLoading(false); // Stop loading indicator
      }
    };

    fetchUsers();
  }, []);

  if (loading) {
    return <div className="loading">Loading users...</div>; // Display loading while data is being fetched
  }

  return (
    <div className="customer-support">
      <header>
        <h2>Customer Support</h2>
      </header>
      
      <div className="user-table-container">
        <table className="user-table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Joined Date</th>
            </tr>
          </thead>
          <tbody>
            {users.map((user, index) => (
              <tr key={index}>
                <td>{user.name}</td>
                <td>{user.email}</td>
                <td>{user.joinedDate}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CustomerSupport;
