// src/AdminDashboard.js
import React from 'react';
import './Dashboard.css';
import { Route, Routes } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Box, Drawer, List, ListItem, ListItemText, Toolbar, AppBar, Typography } from '@mui/material';
import { useAuth } from './AuthProvider'; // AuthProvider for logout



import Notifications from '../pages/notificationpage';
import Settings from './Settings';

import CustomerSupport from './CustomerSupport';

import AddVideoPage from './AddVideo'; // Adjust path according to your file structure
import Appointment from './Appointment'; // Import the Appointment component


import NotificationsIcon from '@mui/icons-material/Notifications';
import SettingsIcon from '@mui/icons-material/Settings';

import SupportIcon from '@mui/icons-material/Support';

import YouTubeIcon from '@mui/icons-material/YouTube';
import EventIcon from '@mui/icons-material/Event'; // Import icon for Appointments

const drawerWidth = 240;

const AdminDashboard = () => {
  const { logout } = useAuth();

  return (
    <Box sx={{ display: 'flex' }}>
      {/* AppBar with logo and logout */}
      <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <Toolbar>
          <img src={"logo512.png"} alt="logo" style={{ height: 40, marginRight: 16 }} />
          <Typography variant="h6" noWrap component="div">
            Farmer's Touch Admin Panel
          </Typography>
          <button onClick={logout} style={{ marginLeft: 'auto', color: 'white', background: 'none', border: 'none' }}>
            Logout
          </button>
        </Toolbar>
      </AppBar>

      {/* Sidebar Drawer */}
      <Drawer
        variant="permanent"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
      >
        <Toolbar />
        <List>
          {[
           
            { text: 'Appointments', path: 'appointments', icon: <EventIcon /> },
           
           
            { text: 'Notifications', path: 'notifications', icon: <NotificationsIcon /> },
            { text: 'Settings', path: 'settings', icon: <SettingsIcon /> },
            
            { text: 'Customer Support', path: 'customer-support', icon: <SupportIcon /> },
            
            { text: 'Add Youtube Video', path: 'add-youtube-video', icon: <YouTubeIcon /> },
          ].map((item, index) => (
            <ListItem button component={Link} to={`/admin/${item.path}`} key={index}>
              {item.icon}
              <ListItemText primary={item.text} sx={{ pl: 2 }} />
            </ListItem>
          ))}
        </List>
      </Drawer>

      {/* Main content area */}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          bgcolor: 'background.default',
          p: 3,
          ml: `${drawerWidth}px`,
          mt: 8,
          height: 'calc(100vh - 64px)', // Adjust for AppBar height
          display: 'flex',
          flexDirection: 'column',
          overflowY: 'auto', // Ensures scrolling if content is tall
        }}
      >
        <Routes>
         
          <Route path="appointments" element={<Appointment />} />
       
        
          <Route path="notifications" element={<Notifications />} />
          <Route path="settings" element={<Settings />} />
       
          <Route path="customer-support" element={<CustomerSupport />} />
          
          <Route path="add-youtube-video" element={<AddVideoPage />} />
          
          {/* Add other routes as needed */}
        </Routes>
      </Box>
    </Box>
  );
};

export default AdminDashboard;
