import React from 'react';
import './App.css';  // Ensure App.css is imported
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AuthProvider, useAuth } from './components/AuthProvider';
import AdminLoginPage from './pages/AdminLoginPage';
import AdminDashboard from './components/AdminDashboard';
import { Navigate } from 'react-router-dom';
import AdminRegisterPage from './pages/AdminRegistrationPage'
import VideoList from './pages/VideoList'; // Adjust based on the actual location
import Appointment from './components/Appointment';

// Admin Dashboard Component

// Define the drawer width for consistent layout
//const drawerWidth = 240;

// Custom PrivateRoute component to protect routes based on authentication status
function PrivateRoute({ children }) {
  const { currentUser } = useAuth();
  return currentUser ? children : <Navigate to="/login" />;
}

function App() {
  return (
    <Router>
      <AuthProvider>
        <div className="App">
          <Routes>
            <Route path="/login" element={<AdminLoginPage />} />
            <Route path="/admin/register" element={<AdminRegisterPage />} />
            <Route path="/admin/*" element={<PrivateRoute><AdminDashboard /></PrivateRoute>} />
            <Route path="/video-list" element={<VideoList />} />
            <Route path="/appointments" element={<Appointment />} />

            <Route path="*" element={<Navigate to="/login" />} />
          </Routes>
        </div>
      </AuthProvider>
    </Router>
  );
}

export default App;

