import React , {useState} from 'react';
import sendNotification from '../components/notification';
import { Form } from 'react-router-dom';

const NotificationForm = () => {
    const [title, setTitle] = useState('');
    const [message, setMessage] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        sendNotification(title,message);

        
    };
    return (
        <form onSubmit={handleSubmit}>
            <input type="text"
            placeholder='Notification Title'
            value={title}
            onChange={(e) => setTitle(e.target.value)} />
            <button type='Submit'> Send Notification</button>
        </form>
    );
};
export default NotificationForm;


