import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, db } from '../firebase'; // Import auth and Firestore from firebase.js
import { createUserWithEmailAndPassword } from 'firebase/auth'; // Import Firebase Auth method
import { doc, setDoc } from 'firebase/firestore'; // Import Firestore methods
import '../components/AdminRegisterPage.css'; // Optional CSS import

const AdminRegisterPage = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(''); // Clear any previous errors

    try {
      // Create user with Firebase Authentication
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      
      // Get the newly created user
      const user = userCredential.user;

      // Store additional user information (name, email) in Firestore
      await setDoc(doc(db, 'administrators', user.uid), {
        name: name,
        email: email,
        createdAt: new Date()
      });

      // Redirect to the admin panel after successful registration
      navigate('/admin');
    } catch (error) {
      setError(error.message); // Display error message
    }
  };

  return (
    <div>
      <h1>Admin Registration</h1>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Name:</label>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Email:</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Password:</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <button type="submit">Register</button>
        {error && <p style={{ color: 'red' }}>{error}</p>}
      </form>
    </div>
  );
};

export default AdminRegisterPage;
