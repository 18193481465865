import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { db } from '../firebase';
import { collection, addDoc } from 'firebase/firestore';
import './AddVideo.css'; // We'll create this CSS file for styling

const AddVideoPage = () => {
  const [video, setVideo] = useState({
    id: '',
    title: '',
    description: '',
    videoUrl: '',
    comment: ''
  });

  const navigate = useNavigate();

  const handleChange = (e) => {
    setVideo({ ...video, [e.target.name]: e.target.value });
  };

  const extractVideoId = (url) => {
    try {
      // Enhanced regex to capture video ID even with query parameters
      const videoIdRegex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})(?:[?&].*)?/;
      const match = url.match(videoIdRegex);
      return match ? match[1] : null;
    } catch (error) {
      console.error("Invalid URL format", error);
      return null;
    }
  };
  
  

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const videoId = extractVideoId(video.videoUrl);
    if (!videoId) {
      alert("Please enter a valid YouTube URL.");
      return;
    }
  
    const newVideo = { ...video, id: videoId };
  
    try {
      const docRef = await addDoc(collection(db, 'videos'), newVideo);
      console.log("Document written with ID: ", docRef.id);
      alert("Video successfully submitted!");
      setVideo({
        id: '',
        title: '',
        description: '',
        videoUrl: '',
        comment: ''
      });
    } catch (error) {
      console.error("Error adding document: ", error);
      alert("There was an error submitting the video.");
    }
  };
  

  return (
    <div className="add-video-container">
      <header>
        <h1>Add YouTube Video</h1>
      </header>
      <button className="video-list-btn" onClick={() => navigate('/video-list')}>
        VIDEO LIST
      </button>

      <form onSubmit={handleSubmit} className="add-video-form">
        <div className="form-group">
          <label>Title:</label>
          <input
            type="text"
            name="title"
            value={video.title}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label>Description:</label>
          <textarea
            name="description"
            value={video.description}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label>Video URL:</label>
          <input
            type="url"
            name="videoUrl"
            value={video.videoUrl}
            onChange={handleChange}
            required
            placeholder="Enter a valid YouTube URL"
          />
        </div>
        <div className="form-group">
          <label>Comment:</label>
          <textarea
            name="comment"
            value={video.comment}
            onChange={handleChange}
          />
        </div>
        <button type="submit" className="submit-btn">Add Video</button>
      </form>
    </div>
  );
};

export default AddVideoPage;
