import React, { useState, useEffect } from 'react';
import { db } from '../firebase'; // Ensure Firebase is initialized here
import { collection, getDocs, deleteDoc, doc, updateDoc } from 'firebase/firestore'; // Import Firestore functions
 // Import react-youtube
import YouTube from 'react-youtube';
import '../components/VideoList.css';  // Updated import statement

const VideoList = () => {
  const [videos, setVideos] = useState([]);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [action, setAction] = useState(null); // For play, edit, delete
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [loading, setLoading] = useState(true); // Loading state for videos

  // Fetch videos from Firestore on component mount
  useEffect(() => {
    const fetchVideos = async () => {
      try {
        const videoCollection = await getDocs(collection(db, 'videos')); // Fetching from the 'videos' collection
        setVideos(videoCollection.docs.map(doc => ({ ...doc.data(), id: doc.id })));
      } catch (error) {
        console.error('Error fetching videos:', error); // Log any error during fetching
      } finally {
        setLoading(false); // Set loading to false regardless of success or failure
      }
    };
    fetchVideos();
  }, []);

  // Helper function to extract YouTube video ID from various URL formats
  const getYouTubeVideoId = (url) => {
    if (!url) return null;
    
    // No need to escape the forward slash in a regex
    const match = url.match(/(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/);
    
    console.log('Extracted Video ID:', match ? match[1] : 'No match found');
    
    return match ? match[1] : null;
  };
  
  

  // Handle delete confirmation
  const handleDelete = (videoId) => {
    setConfirmDelete(true);
    setSelectedVideo(videoId);
  };

  const confirmDeleteVideo = async () => {
    try {
      await deleteDoc(doc(db, 'videos', selectedVideo)); // Delete video from Firestore
      setVideos(videos.filter(video => video.id !== selectedVideo));
      setSelectedVideo(null);
      setConfirmDelete(false);
      setAction(null); // Reset action after deletion
    } catch (error) {
      console.error('Error deleting video:', error); // Log any error during deletion
    }
  };

  // Handle play functionality
  const handlePlay = (videoId) => {
    const video = videos.find(v => v.id === videoId);
    if (video && video.url) { // Ensure video and URL are defined
      const extractedVideoId = getYouTubeVideoId(video.url); // Extract video ID
      if (extractedVideoId) {
        setSelectedVideo({ ...video, videoId: extractedVideoId }); // Set the videoId correctly
        setAction('play');
      } else {
        console.error('Invalid YouTube URL or could not extract video ID:', video.url);
      }
    } else {
      console.error('Video or URL is not available.');
    }
  };
  

  // Handle edit functionality
  const handleEdit = (videoId) => {
    const video = videos.find(v => v.id === videoId);
    setAction('edit');
    setSelectedVideo(video);
  };

  // Update video details in Firestore
  const updateVideoDetails = async (updatedVideo) => {
    if (!selectedVideo || !selectedVideo.id) {
      console.error('Selected video is not valid for update');
      return; // Prevent update if selectedVideo is invalid
    }

    try {
      await updateDoc(doc(db, 'videos', selectedVideo.id), updatedVideo); // Use updateDoc to update Firestore
      const updatedVideos = videos.map(video => 
        video.id === selectedVideo.id ? { ...updatedVideo, id: selectedVideo.id } : video
      );
      setVideos(updatedVideos);
      setAction(null);
      setSelectedVideo(null);
    } catch (error) {
      console.error('Error updating video:', error); // Log any error during update
    }
  };

  return (
    <div className="video-list-container">
      <header>
        <h1>YouTube Videos</h1>
      </header>

      {loading ? (
        <p className="loading">Loading videos...</p>
      ) : (
        <div className="video-list-scroll">
          {videos.map((video) => (
            <div key={video.id} className="video-item">
              <h4>{video.title}</h4>
              <div className="video-actions">
                <button onClick={() => handlePlay(video.id)}>Play</button>
                <button onClick={() => handleEdit(video.id)}>Edit</button>
                <button onClick={() => handleDelete(video.id)}>Delete</button>
              </div>
            </div>
          ))}
        </div>
      )}

      {action === 'play' && selectedVideo && selectedVideo.videoId && (
        <div className="video-player">
          <h2>{selectedVideo.title}</h2>
          <YouTube videoId={selectedVideo.videoId} />
          <button onClick={() => {
            setSelectedVideo(null);
            setAction(null);
          }}>
            Close Player
          </button>
        </div>
      )}

      {action === 'edit' && selectedVideo && (
        <div className="edit-video-form">
          <h2>Edit Video</h2>
          <input
            type="text"
            value={selectedVideo?.title || ''}
            onChange={(e) => setSelectedVideo({ ...selectedVideo, title: e.target.value })}
          />
          <input
            type="text"
            value={selectedVideo?.description || ''}
            onChange={(e) => setSelectedVideo({ ...selectedVideo, description: e.target.value })}
          />
          <div className="edit-form-buttons">
            <button className="edit-form-btn save-btn" onClick={() => updateVideoDetails(selectedVideo)}>Save</button>
            <button className="edit-form-btn cancel-btn" onClick={() => {
              setSelectedVideo(null);
              setAction(null);
            }}>Cancel</button>
          </div>
        </div>
      )}

      {confirmDelete && (
        <div className="delete-confirmation">
          <p>Are you sure you want to delete this video?</p>
          <button onClick={confirmDeleteVideo}>Yes, Delete</button>
          <button onClick={() => setConfirmDelete(false)}>Cancel</button>
        </div>
      )}
    </div>
  );
};

export default VideoList;
