import axios  from 'axios' ;

const sendNotification = async (notificationTitle,notificationMessage) => {
    const oneSignalApiUrl = 'https://onesignal.com/api/v1/notifications';
    const oneSignalAppId = process.env.REACT_APP_ONESIGNAL_APP_ID ;
    const oneSignalApiKey = process.env.REACT_APP_ONESIGNAL_REST_API_KEY ;

    const notificationData = {
        app_id: oneSignalAppId,
        headings: {en : notificationTitle},
        contents: {en : notificationMessage},
        included_segments: ['Subscribed Users'],
    };

    try {
        const response = await axios.post(oneSignalApiUrl, notificationData, {
            headers: {
                Authorization: `Basic ${oneSignalApiKey}`,
                'Content-Type': 'application/json',  
            },
        });

        console.log("Notification sent: ", response.data);
    } catch (error) {
        console.error('Error sending notification: ', error);
    }
};

export default sendNotification;