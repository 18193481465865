import React, { useState, useEffect } from 'react';
import { db } from '../firebase';
import { collection, getDocs } from 'firebase/firestore';
import './AppointmentManagement.css';

const Appointment = () => {
  const [appointments, setAppointments] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchAppointments = async () => {
      try {
        const appointmentCollection = await getDocs(collection(db, 'appointments'));
        setAppointments(appointmentCollection.docs.map(doc => {
          const data = doc.data();
          return {
            ...data,
            id: doc.id,
            date: data.date ? data.date.toDate().toLocaleDateString() : 'N/A',
            time: data.time || 'N/A'
          };
        }));
      } catch (error) {
        console.error('Error fetching appointments:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchAppointments();
  }, []);

  return (
    <div className="appointment-management">
      <header>
        <h2>Appointment Management</h2>
      </header>
      
      {loading ? (
        <p className="loading">Loading appointments...</p>
      ) : (
        <div className="appointment-table-container">
          <table className="appointment-table">
            <thead>
              <tr>
                <th>Farmer</th>
                <th>Email</th>
                <th>Appointment Title</th>
                <th>Location</th>
                <th>Contact</th>
                <th>Date</th>
                <th>Time</th>
                <th>Details</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {appointments.map((appointment) => (
                <tr key={appointment.id}>
                  <td>{appointment.userName || 'N/A'}</td>
                  <td>{appointment.userEmail || 'N/A'}</td>
                  <td>{appointment.title || 'N/A'}</td>
                  <td>{appointment.farmLocation || 'N/A'}</td>
                  <td>{appointment.contact || 'N/A'}</td>
                  <td>{appointment.date}</td>
                  <td>{appointment.time}</td>
                  <td>{appointment.comments || 'N/A'}</td>
                  <td>
                    <div className="action-buttons">
                      <button className="edit-btn">Edit</button>
                      <button className="delete-btn">Cancel</button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default Appointment;
